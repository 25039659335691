<template>
  <section class="q-pa-md">
    <h1>Sign up</h1>
    <q-form
      autofocus
      class="q-gutter-md"
      @submit.prevent="submit"
    >
      <q-input
        v-model="innerValue.email"
        label="Email"
        type="text"
      />
      <q-input
        v-model="innerValue.fseconomyAccessKey"
        :rules="[
  value => !!value || 'This field is required',
  value => 'null' !== value || 'Access key is must not be null, click the Reset button next to access key in FSEconomy\'s data feeds page to get a 16-character access key',
]"
        bottom-slots
        counter
        label="FSEconomy user read access key"
        maxlength="16"
        name="fseconomyAccessKey"
        type="text"
      >
        <template v-slot:hint>
          <div>
            A 16-character key found next to your username in
            <a
              href="https://server.fseconomy.net/datafeeds.jsp"
              target="_blank"
            >the data feeds page</a>.
          </div>
        </template>
      </q-input>
      <q-banner
        class="bg-grey-4"
        dense
      >
        <template v-slot:avatar>
          <q-icon
            color="primary"
            name="fas fa-info-circle"
          />
        </template>
        <p>
          <strong>About FSEconomy read access key</strong>
        </p>
        <div>
          FSE-FBO5 needs your read access key to access the following FSEconomy
          data feeds:
          <ul>
            <li>Aircraft By Key</li>
            <li>Assignments By Key</li>
            <li>Facilities By Key</li>
            <li>ICAO Listing of Aircraft</li>
            <li>ICAO Jobs From</li>
          </ul>
        </div>
        <p>
          The read access key only provides <strong>read-only</strong> access
          and can never be used to make any changes to your FSEconomy account.
        </p>
        <p>
          You may reset your read access key at any time from FSEconomy's data
          feeds page to either temporarily or permanently revoke our read-only
          access to your FSEconomy account, although doing so may result in an
          interruption to your FSE-FBO5 services.
        </p>
        <template v-slot:action>
          <q-btn
            flat
            href="https://sites.google.com/site/fseoperationsguide/expansion/data-feeds#TOC-Access-Key"
            label="Learn more"
            target="_blank"
            type="a"
          />
        </template>
      </q-banner>
      <q-btn
        color="primary"
        label="Sign up"
        type="submit"
      />
      <p>
        Already have an account?
        <q-btn
          :to="`/${$route.params.locale}/sign-in`"
          label="Sign in"
        />
      </p>
      <p>
        Forgot your password?
        <q-btn
          :to="`/${$route.params.locale}/reset-password`"
          label="Reset password"
        />
      </p>
    </q-form>
  </section>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  const moduleName = 'auth'
  const { mapActions } = createNamespacedHelpers(
    moduleName,
  )

  export default {
    meta: {
      title: 'Sign up',
    },
    methods: {
      ...mapActions(
        [
          'signUp',
        ],
      ),
      async submit () {
        try {
          await this.signUp(
            this.innerValue,
          )
        } catch (error) {
          return
        }

        await this.$router.push(
          `/${this.$route.params.locale}/sign-in`,
        )
      },
    },
    data () {
      return {
        innerValue: {},
      }
    },
  }
</script>
